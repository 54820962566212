
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { QuizInterface } from '@/interface/BaseInterface'
  import { assign } from 'lodash'

  @Component
  export default class QuizType extends Vue {
    @Prop({ required: false, type: String, default: '' }) moduleId?: string
    @Prop({ required: true, type: Object }) data!: QuizInterface
    @Prop({ required: false, type: Boolean, default: true }) showAction!: boolean

    confictClick = false

    get checkStatus(): string {
      const now = new Date()
      if (!this.data.startDate || !this.data.endDate) {
        return 'close'
      }

      const startDate = new Date(this.data.startDate)
      if (this.daysBetween(now, startDate) < 0) {
        return 'close'
      }

      const endDate = new Date(this.data.endDate)
      if (this.daysBetween(now, endDate) > 0) {
        return 'coming'
      }

      return 'open'
    }

    daysBetween(first: Date, second: Date): number {
      // Copy date parts of the timestamps, discarding the time parts.
      const one = new Date(first.getFullYear(), first.getMonth(), first.getDate(), 0, 0, 0, 0)
      const two = new Date(second.getFullYear(), second.getMonth(), second.getDate(), 0, 0, 0)

      return one.getTime() - two.getTime()
    }

    handleCommand(command: 'remove' | 'edit') {
      if (command === 'remove') {
        this.$confirm(this.$t('deleteQuestion') as string, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.$emit(
            'remove',
            assign(this.data, {
              moduleType: 'Quiz',
              moduleId: this.moduleId
            })
          )
        })
      }
    }

    handleClick() {
      if (this.confictClick) {
        this.confictClick = false
        return
      }
      // if (this.checkRole(['ADMIN', 'COURSE_ADMIN', 'COURSE_TEACHER']) || this.checkStatus === 'open') {
      this.$router.push({
        name: 'quizDetail',
        params: {
          id: this.courseId,
          quizId: this.data._id
        }
      })
      // }
    }
  }
