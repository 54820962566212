
  import { Vue, Component } from 'vue-property-decorator'
  import { QuizInterface } from '@/interface/BaseInterface'
  import QuizApi from '../../api/quizApi'
  import getApi from '@/api'
  import QuizType from '../../components/section/type/QuizType.vue'

  const quizApi: QuizApi = getApi('quiz')

  @Component({
    components: { QuizType }
  })
  export default class ListQuizzes extends Vue {
    activeNames = 'assign'

    quizzes: QuizInterface[] = []

    async handleAdd() {
      // this.$router.push({
      //   name: 'quizEdit',
      //   params: {
      //     id: this.courseId
      //   }
      // })
    }

    async init() {
      try {
        this.quizzes = await quizApi.all({
          courseId: this.courseId
        })
      } catch (error) {
        console.log(error)
      }
    }

    created() {
      this.init()
    }
  }
